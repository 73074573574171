import React from 'react';
import { PapiUser } from '@wix/da-papi-types';
import BrowsePage from './variants/BrowsePage';
import GroupPage from './variants/GroupPage';
import DailyDeviationPage from './variants/DailyDeviationPage';
import SearchPage from './variants/SearchPage';
import TagPage from './variants/TagPage';
import WatchPage from './variants/WatchPage';
import ShopPage from './variants/ShopPage';
import ArtistWatchPage from './variants/ArtistWatchPage';
import HomePage from './variants/HomePage';

export interface Props {
  pageType?: string;
  thisUser?: PapiUser;
}

const Page: React.FC<Props> = ({ pageType, thisUser }) => {
  switch (pageType) {
    case 'groups':
      return <GroupPage />;
    case 'daily-deviations':
      return <DailyDeviationPage />;
    case 'tag':
      return <TagPage />;
    case 'search':
      return <SearchPage />;
    case 'watch':
      return thisUser ? <ArtistWatchPage thisUser={thisUser} /> : <WatchPage />;
    case 'shop':
      return <ShopPage />;
    case 'home':
      return <HomePage />;
    default:
      return <BrowsePage pageType={pageType} />;
  }
};
Page.displayName = 'Page';

export default Page;
