import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Url } from '@wix/da-url';
import { PapiUser } from '@wix/da-papi-types';
import Ellipsis from '@wix/da-ds/pkg/Icons/Ellipsis';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import IconButtonWithContextMenu from '@wix/da-ds/pkg/Dropdown/Preset/IconButtonWithContextMenu';
import { useWatchButton } from '@wix/da-shared-react/pkg/WatchButton/useWatchButton';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import {
  BiData,
  BiContextProvidedValue,
  UserNameClickBiEvent,
  WatchMenuItemSelectionBiEvent,
  MoreMenuClickBiEvent,
} from '@wix/da-bi/pkg/events';
import { ResourceType } from '@wix/da-shared-react/pkg/types/resource';

export interface Props {
  group: PapiUser;
  className?: string;
  requestLeave: (group: PapiUser) => void;
}

const GroupMenu: React.FC<Props> = ({ group, className, requestLeave }) => {
  const isMobile = useContext(MobileContext);
  const { t } = useTranslation();
  const {
    isWatching,
    biData: watchBiData,
    onClick,
  } = useWatchButton(group, group.isWatching);

  const dropdownItems = useMemo(() => {
    const itemBiData = BiData<WatchMenuItemSelectionBiEvent>({
      evid: 424,
      itemid: group.userId,
      typeid: ResourceType.GROUP,
      menu_item: '',
    });
    return [
      isMobile && {
        label: t('subnavigation.button.groupDropdown.items.visitGroup'),
        link: Url.userLink(group.username),
        biData: BiData<UserNameClickBiEvent>({
          evid: 101,
          itemid: group.userId,
          typeid: ResourceType.GROUP,
          sectionname: BiContextProvidedValue,
        }),
      },
      isMobile &&
        group.extendedGroup?.isAdmin && {
          label: t('subnavigation.button.groupDropdown.items.notifications'),
          link: Url.groupNotificationsLink(group.userId),
          biData: {
            ...itemBiData,
            menu_item: 'visitNotifications',
          },
        },
      {
        label: t('subnavigation.button.groupDropdown.items.messageAdmins'),
        link: Url.userNoteLink(group.username),
        biData: {
          ...itemBiData,
          menu_item: 'messageAdmins',
        },
      },
      {
        label: isWatching
          ? t('subnavigation.button.groupDropdown.items.unwatch')
          : t('subnavigation.button.groupDropdown.items.watch'),
        biData: watchBiData,
        onClick,
      },
      group.extendedGroup?.isIngroup && {
        label: t('subnavigation.button.groupDropdown.items.leaveGroup'),
        onClick: () => requestLeave(group),
        biData: {
          ...itemBiData,
          menu_item: 'leaveGroup',
        },
      },
    ];
  }, [group, watchBiData, isWatching, onClick, requestLeave, t, isMobile]);

  return (
    <IconButtonWithContextMenu
      items={dropdownItems}
      className={className}
      onOpenBiData={BiData<MoreMenuClickBiEvent>({
        evid: 417,
        typeid: ResourceType.GROUP,
        itemid: group.userId,
      })}
      withButton
    >
      <Ellipsis size={IconSize.MEDIUM} />
    </IconButtonWithContextMenu>
  );
};
GroupMenu.displayName = 'GroupMenu';

export default GroupMenu;
