import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ThemeOverride,
  ThemeSurface,
} from '@wix/da-react-context/pkg/ThemeContext';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { useFlag } from '@wix/da-react-context/pkg/flags/hooks/useFlag';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import Header from '../../../../../Header';
import OrderDropdown from '../../../../_partials/OrderDropdown';
import { DailyChallengeArray } from '../types';
import DailyChallengeSlider from './DailyChallengeSlider';

import v1Styles from './DailyChallengePageHeader.scss';
import v2Styles from './DailyChallengePageHeader.daily_challenge_v2.scss';

export interface Props {
  tag: string;
  dailyChallenges: DailyChallengeArray;
}

const DailyChallengePageHeader: React.FC<Props> = ({
  tag,
  dailyChallenges,
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);

  const daily_challenge_v2 = useFlag('daily_challenge_v2');
  const s = daily_challenge_v2 ? v2Styles : v1Styles;

  return (
    <Header
      className={s['root']}
      innerClassName={s['inner']}
      isSticky={!isMobile}
      leftSideContent={
        <Heading className={s['title']}>
          {t('tagPage.dailyChallenge.title')}
        </Heading>
      }
      rightSideContent={<OrderDropdown className={s['order-dropdown']} />}
      rightSideClassName={s['right-side']}
      subheader={
        <ThemeOverride
          className={s['slider-container']}
          themeSurface={ThemeSurface.SECONDARY}
        >
          <DailyChallengeSlider
            className={s['slider']}
            tag={tag}
            dailyChallenges={dailyChallenges}
          />
        </ThemeOverride>
      }
    />
  );
};
DailyChallengePageHeader.displayName = 'DailyChallengePageHeader';

export default DailyChallengePageHeader;
