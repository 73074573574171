import React, { useContext } from 'react';
import classnames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { Url } from '@wix/da-url';
import GhostButton from '@wix/da-ds/pkg/Buttons/GhostButton';
import Flex from '@wix/da-ds/pkg/Flex';
import DreamUpStars from '@wix/da-ds/pkg/Icons/24x24/DreamUpStars';
import { useFlag } from '@wix/da-react-context/pkg/flags/hooks/useFlag';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { ResourceType } from '@wix/da-shared-react/pkg/types/resource';
import { BiData, DreamupInfoClickBiEvent } from '@wix/da-bi/pkg/events';
import { SessionContext } from '@wix/da-react-context/pkg/publicSession';
import { Section } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import SolidButton from '@wix/da-ds/pkg/Buttons/SolidButton';
import OrderDropdown from '../../../../_partials/OrderDropdown';
import useSubmitButtonProps from '../../../../_partials/SubmitButton/useSubmitButtonProps';
import { DailyChallengeArray } from '../types';

import v1Styles from './SelectedDailyChallengeHeading.scss';
import v2Styles from './SelectedDailyChallengeHeading.daily_challenge_v2.scss';

export interface Props {
  tag: string;
  tagItemId: number;
  dailyChallenges: DailyChallengeArray;
  className?: string;
}

// todo maybe we don't need the full challenge? just tag and isToday?
const SelectedDailyChallengeHeading: React.FC<Props> = ({
  tag,
  tagItemId,
  dailyChallenges,
  className,
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);

  const daily_challenge_v2 = useFlag('daily_challenge_v2');
  const s = daily_challenge_v2 ? v2Styles : v1Styles;
  const isMobileV2 = daily_challenge_v2 && isMobile;

  const { aiContentFilter } = useContext(SessionContext);
  const submitButtonProps = useSubmitButtonProps({
    tags: tag,
    biSectionname: 'tags',
  });

  const isAiContentSuppressed = aiContentFilter === 'suppress';

  if (!tag || !Array.isArray(dailyChallenges)) {
    return null;
  }

  // we don't have anything from them to tell -which- challenge matched the current tag
  const { today } =
    dailyChallenges.find(challenge => challenge.tag.tag === tag) || {};

  return (
    <Section className={classnames(className, s['root'])}>
      {!isMobileV2 && (
        <Heading className={s['current-tag']}>{`#${tag}`}</Heading>
      )}
      {isMobileV2 && (
        <div className={s['mobile-heading-and-orderdropdown']}>
          <Heading className={s['current-tag']}>{`#${tag}`}</Heading>
          <OrderDropdown className={s['order-dropdown']} />
        </div>
      )}

      <p className={s['challenge-text']}>
        <Trans
          i18nKey="tagPage.dailyChallenge.challengeText"
          specifier={t(
            today
              ? 'tagPage.dailyChallenge.challengeText.themeSpecifier.today'
              : 'tagPage.dailyChallenge.challengeText.themeSpecifier.notToday'
          )}
        >
          Create and submit artwork each day using the
          <a href={t('tagPage.dailyChallenge.challengeUrl')}>Drawtober 2023</a>
          prompt list to earn Profile badges!
        </Trans>
      </p>
      <Flex className={s['submit-container']}>
        {!isAiContentSuppressed && (
          <GhostButton
            variant="main"
            size={isMobileV2 ? 'medium' : 'large'}
            className={s['dreamup-cta']}
            icon={DreamUpStars}
            href={Url.dreamupLink({ prompt: tag })}
            biData={BiData<DreamupInfoClickBiEvent>({
              evid: 923,
              typeid: ResourceType.DISCOVERYTAG,
              itemid: tagItemId,
            })}
          >
            {isMobile
              ? t('tagPage.dailyChallenge.createWithDreamup.mobileText')
              : t('tagPage.dailyChallenge.createWithDreamup.text')}
          </GhostButton>
        )}
        {isMobile ? (
          <SolidButton
            className={s['submit-button']}
            variant="approval-green"
            size="medium"
            {...submitButtonProps}
          >
            {t('tagPage.dailyChallenge.submitYourArt')}
          </SolidButton>
        ) : (
          <SolidButton
            className={s['submit-button']}
            variant="approval-green"
            size="large"
            {...submitButtonProps}
          >
            {t('tagPage.dailyChallenge.submitTo', { tag })}
          </SolidButton>
        )}
      </Flex>
    </Section>
  );
};
SelectedDailyChallengeHeading.displayName = 'SelectedDailyChallengeHeading';

export default SelectedDailyChallengeHeading;
