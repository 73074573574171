// DS5.8
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import { PapiUser } from '@wix/da-papi-types';
import Slider, {
  DefaultPreviousButton,
  DefaultNextButton,
} from '@wix/da-ds/pkg/Slider';
import { IconSize } from '@wix/da-ds/pkg/Icons/IconWrap';
import { isInsideEditor } from '@wix/da-shared-react/pkg/DaEditorWrapper/utils';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import { FirstTimeFlag } from '@wix/da-shared-react/pkg/redux/firstTimeFlags/constants';
import { useFirstTimeFlag } from '@wix/da-shared-react/pkg/redux/firstTimeFlags/hooks';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import VisuallyHidden from '@wix/da-a11y/pkg/components/VisuallyHidden';
import { Section } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import DeviantsSearchButton from '../DeviantsSearchButton';
import DeviantsBarItem from './DeviantsBarItem';
import AllButton from './AllButton';
import OriginalSubscribedFilter from './SubscribedFilter';
import SubscribedFilterFTUETooltip from './SubscribedFilterFTUETooltip';
import type { SubNavigation } from '../../types/store';
import DeviantsBarUserItem from './DeviantsBarUserItem';
import s from './DeviantsBar.scss';
import {
  BiData,
  WatchFilterSelectAllClickBiEvent,
} from '@wix/da-bi/pkg/events';

export interface Props {
  users: PapiUser[];
  filter?: SubNavigation;
  activeUserId?: number;
  allHasNew?: boolean;
  allUrl: string;
}

function getPrevUserUrl(
  users: PapiUser[],
  activeUserIndex: number,
  allUrl: string
) {
  if (activeUserIndex > 0) {
    return users[activeUserIndex - 1]?.networkbar?.url;
  }

  if (activeUserIndex === 0) {
    return allUrl;
  }
}

function getNextUserUrl(users: PapiUser[], activeUserIndex: number) {
  if (activeUserIndex < users.length - 1) {
    return users[activeUserIndex + 1]?.networkbar?.url;
  }
}

function getScrollToDeviantIndex(activeUserIndex: number, isMobile: boolean) {
  // on mobile, we have the all button inside the slider so we need to add one
  if (isMobile) {
    return activeUserIndex + 1;
  }

  return activeUserIndex;
}

const DeviantsBar: React.FC<Props> = ({
  users,
  activeUserId,
  allUrl,
  allHasNew,
  filter,
}) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const { hasFlag: hasFilterFTUEFlag } = useFirstTimeFlag(
    FirstTimeFlag.IsDyw3SubscriptionsFilterNotSeen
  );
  const SubscribedFilter = hasFilterFTUEFlag
    ? SubscribedFilterFTUETooltip
    : OriginalSubscribedFilter;

  // user active userid to scroll to correct user avatar
  const activeUserIndex = users.findIndex(user => user.userId === activeUserId);
  const prevDeviantUrl = getPrevUserUrl(users, activeUserIndex, allUrl);
  const nextDeviantUrl = getNextUserUrl(users, activeUserIndex);
  const scrollToIndex = getScrollToDeviantIndex(activeUserIndex, isMobile);
  const subscriberOnly = filter?.currentValue === 'subscribed';

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      const target = e.target as Element;

      if (
        // avoid interfering with any text inputs
        target &&
        (['INPUT', 'TEXTAREA'].includes(target.tagName) ||
          isInsideEditor(target))
      ) {
        return;
      }

      switch (e.key) {
        case 'ArrowRight':
          nextDeviantUrl && window.location.assign(nextDeviantUrl);
          break;
        case 'ArrowLeft':
          prevDeviantUrl && window.location.assign(prevDeviantUrl);
          break;
        default:
          return;
      }
    };

    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, [nextDeviantUrl, prevDeviantUrl]);

  const allButton = filter ? (
    <SubscribedFilter
      filter={filter}
      hasNewContent={allHasNew}
      isActive={!activeUserId}
      biData={BiData<WatchFilterSelectAllClickBiEvent>({
        evid: 411,
        select_all_type: 'all',
      })}
      size={isMobile ? 'small' : 'regular'}
    />
  ) : (
    <DeviantsBarItem
      className={s['bar-item']}
      url={allUrl}
      hasNewContent={allHasNew}
      isActive={!activeUserId}
      label={t('browseHeader.page.all')}
      biData={BiData<WatchFilterSelectAllClickBiEvent>({
        evid: 411,
        select_all_type: 'all',
      })}
      size={isMobile ? 'medium' : 'large'}
    >
      <AllButton
        // Avoid duplication for screen readers with the provided label
        aria-hidden="true"
        size={isMobile ? 'small' : 'regular'}
      />
    </DeviantsBarItem>
  );

  return (
    <BiLoggerContextProvider value={{ sectionname: 'deviantsbar' }}>
      <Section className={s['root']}>
        <VisuallyHidden>
          <Heading>{t('deviantsBar.a11y.heading')}</Heading>
        </VisuallyHidden>
        {!isMobile && allButton}
        <Slider
          className={s['slider']}
          scrollToIndex={scrollToIndex}
          renderNextButton={({ onClick, className }) => (
            <DefaultNextButton
              onClick={onClick}
              className={c(className, s['slider-next-button'])}
              arrowSize={IconSize.SMALL}
            />
          )}
          renderPrevButton={({ onClick, className }) => (
            <DefaultPreviousButton
              onClick={onClick}
              className={c(className, s['slider-prev-button'])}
              arrowSize={IconSize.SMALL}
            />
          )}
          smoothInitialScroll={false}
          noSpacing
          enableMobileSwipeLogging
        >
          {isMobile && allButton}
          {users.map(user => (
            <DeviantsBarUserItem
              key={user.userId}
              className={s['bar-item']}
              size={isMobile ? 'medium' : 'large'}
              user={user}
              isActive={user.userId === activeUserId}
              subscriberOnly={subscriberOnly}
            />
          ))}
        </Slider>
        {!isMobile && (
          <div className={s['search-button']}>
            <DeviantsBarItem
              className={s['bar-item']}
              hasNewContent={false}
              isActive={false}
              label={t('browseHeader.page.search')}
              size="large"
            >
              <DeviantsSearchButton selectedUserId={activeUserId} />
            </DeviantsBarItem>
          </div>
        )}
      </Section>
    </BiLoggerContextProvider>
  );
};
DeviantsBar.displayName = 'DeviantsBar';

export default DeviantsBar;
