import 'react-dates/initialize';
import React from 'react';
import DaApp from '@wix/da-shared-react/pkg/DaApp';
import SiteHeader from '@wix/da-shared-react/pkg/SiteHeader';
import { RuntimeEnvironment } from '@wix/da-shared-react/pkg/types';
import { NotificationQueueItem } from '@wix/da-shared-react/pkg/Notifications';
import ErrorBoundary from '@wix/da-react-context/pkg/ErrorBoundary';
import SisuFunnelBanner from '@wix/da-shared-react/pkg/SisuFunnelBanner';
import DrawerLoadable from '@wix/da-feedback-shared/pkg/Drawer/DrawerLoadable';
import FeedbackPageLoadable from '@wix/da-feedback-shared/pkg/FeedbackPage/FeedbackPageLoadable';
import ShowLessNotification from '../ShowLessNotification';
import DywRemoveNotification from '../DywRemoveNotification';
import DywRemoveConfirmationModal from '../DywRemoveConfirmationModal';
import { ModalType, NotificationType } from '../../types/notification';
import { composeModalRenderers } from '@wix/da-shared-react/pkg/Modals/helpers';
import { feedbackModalRenderer } from '@wix/da-feedback-shared/pkg/project-tools';
import { adoptsModalRenderer } from '@wix/da-adopts-shared/pkg/project-tools';
import { submitModalRenderer } from '@wix/da-submit-shared/pkg/project-tools';
import PageNetworkbar from '../Page';

import s from './App.scss';

export interface Props {
  language: string;
  environment?: RuntimeEnvironment;
  activeSiteHeaderItem?: string;
  sisuFunnelBannerVisible?: boolean;
  showNetworkBarInSiteHeader?: boolean;
  searchTerm?: string;
  searchPath?: string;
  pageType?: string;
}

const renderNotification = (
  notification: NotificationQueueItem,
  removeNotification
) => {
  switch (notification.type) {
    case NotificationType.SHOW_LESS:
      return (
        <ShowLessNotification
          id={notification.id}
          queueName={notification.queueName}
          type={notification.type}
          {...notification.params}
        />
      );
    case NotificationType.REMOVE_DYW:
      return (
        <DywRemoveNotification
          id={notification.id}
          queueName={notification.queueName}
          {...notification.params}
        />
      );
    default:
      return null;
  }
};

const renderModal = composeModalRenderers(
  (modalType: string, requestClose: () => void, params?: any) => {
    switch (modalType) {
      case ModalType.REMOVE_CONFIRM_DYW:
        return (
          <DywRemoveConfirmationModal
            biData={params.biData}
            requestClose={requestClose}
            removeAll={params.removeAll}
          />
        );
      default:
        return null;
    }
  },
  feedbackModalRenderer,
  adoptsModalRenderer,
  submitModalRenderer
);

const App: React.FC<Props> = ({
  environment,
  language,
  sisuFunnelBannerVisible,
  showNetworkBarInSiteHeader,
  activeSiteHeaderItem,
  searchTerm,
  searchPath,
  pageType,
}) => {
  return (
    <DaApp
      language={language}
      environment={environment}
      notificationsRenderFunc={renderNotification}
      modalsRenderFunc={renderModal}
      ncContextProps={{
        PageComponent: FeedbackPageLoadable,
        DrawerComponent: DrawerLoadable,
      }}
      disableHeadTags={true}
    >
      <ErrorBoundary debugComponent="BrowseApp">
        <SiteHeader
          showNetworkBar={showNetworkBarInSiteHeader}
          activeMainMenuItem={activeSiteHeaderItem}
          searchQuery={searchTerm}
          searchBasePath={searchPath}
        />
        <div className={s['content-container']}>
          <PageNetworkbar />
        </div>
        {sisuFunnelBannerVisible && (
          <SisuFunnelBanner
            visible={sisuFunnelBannerVisible}
            pageType={pageType}
          />
        )}
      </ErrorBoundary>
    </DaApp>
  );
};
App.displayName = 'App';

export default App;
