import React, { useContext } from 'react';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import {
  default as FeedLayoutShared,
  FeedContent,
  FeedSidebar,
} from '@wix/da-shared-react/pkg/FeedLayout';
import MeasuredViewerContainerSizeContextProvider from '@wix/da-shared-react/pkg/DaEditorWrapper/ViewerContainerSizeContext/MeasuredViewerContainerSizeContextProvider';
import { MeasuredCookieType } from '@wix/da-hooks/pkg/useMeasureElement/redux/types';
import FeedPostbox from './FeedPostbox';
import FeedNotice from './FeedNotice';
import FeedSidebarContent from '../FeedSidebarContent';
import s from './FeedLayout.scss';

export interface Props {
  children?: React.ReactNode;
}

const FeedLayout: React.FC<Props> = ({ children }) => {
  const isMobile = useContext(MobileContext);
  return (
    <>
      {!isMobile && <FeedPostbox className={s['feed-postbox']} />}
      <FeedLayoutShared>
        <FeedContent>
          {isMobile && <FeedPostbox className={s['feed-postbox']} />}
          <FeedNotice className={s['feed-notice']} />
          <MeasuredViewerContainerSizeContextProvider
            cookieType={MeasuredCookieType.BROWSE_POST_FEED}
            cookieDim={'width'}
            className={s['feed-item']}
          >
            {children}
          </MeasuredViewerContainerSizeContextProvider>
        </FeedContent>

        {!isMobile && (
          <FeedSidebar>
            <FeedSidebarContent itemClassName={s['sidebar-block']} />
          </FeedSidebar>
        )}
      </FeedLayoutShared>
    </>
  );
};
FeedLayout.displayName = 'FeedLayout';

export default FeedLayout;
