// DS: MO 3.7
import React from 'react';
import { useTranslation } from 'react-i18next';
import c from 'classnames';
import type { PapiDeviation, PapiNetworkbarStrip } from '@wix/da-papi-types';
import { Url, USER_PROFILE_SHOP_SUB_PATHS } from '@wix/da-url';
import {
  BiData,
  BiContextProvidedValue,
  UserNameClickBiEvent,
} from '@wix/da-bi/pkg/events';
import { BiLoggerContextProvider } from '@wix/da-bi/pkg/BiLogger.context';
import { Section } from '@wix/da-a11y/pkg/components/headings/tagWrappers';
import SolidButton from '@wix/da-ds/pkg/Buttons/SolidButton';
import PlainButtonOrLink from '@wix/da-ds/pkg/Buttons/PlainButtonOrLink/PlainButtonOrLink';
import IdentityVertical from '@wix/da-shared-react/pkg/User/IdentityVertical';
import DeviationDumbView from '@wix/da-shared-react/pkg/DeviationViews/Dumbo';
import { getInfoToDisplayImageDeviation } from '@wix/da-shared-react/pkg/DeviationViews/Dumbo/Image/_helpers';
import { ResourceType } from '@wix/da-shared-react/pkg/types/resource';
import { isGroup } from '@wix/da-shared-react/pkg/User/helpers';

import s from './SellerCard.scss';

const MAX_MULTIART_SHOWN = 3;

export type UsersExtra = NonNullable<PapiNetworkbarStrip['usersExtra']>[0];

interface Props extends UsersExtra {
  width: number;
  withShopCta?: boolean;
  className?: string;
  /** Use a card with three instances of a seller's art instead of a profile pic */
  multiArt?: boolean;
}

const SellerCard: React.FC<Props> = ({
  user,
  link,
  deviations,
  width,
  withShopCta,
  multiArt,
  className,
}) => {
  const { t } = useTranslation();
  const biData = BiData<UserNameClickBiEvent>({
    evid: 101,
    itemid: user.userId,
    typeid: isGroup(user) ? ResourceType.GROUP : ResourceType.USER,
    sectionname: BiContextProvidedValue,
  });

  const rootClassNames = c(
    s['root'],
    'ds-card',
    multiArt && s['multi-art'],
    className
  );

  const multiArtWidth = (width - 8) / 3;
  const background =
    multiArt && deviations ? (
      <div className={s['multi-art-background']}>
        {deviations.slice(0, MAX_MULTIART_SHOWN).map(deviation => {
          return (
            <DeviationDumbView
              key={deviation.deviationId}
              deviation={deviation}
              width={multiArtWidth}
              height={multiArtWidth}
              greenLikeCrop
            />
          );
        })}
      </div>
    ) : (
      <BackgroundDeviation
        deviation={user.profile?.profilePic}
        size={withShopCta ? 'medium' : 'large'}
      />
    );

  return (
    <Section className={rootClassNames} style={{ width }}>
      <BiLoggerContextProvider value={{ sectionname: 'Browse featured' }}>
        {background}
        <PlainButtonOrLink
          role="presentation"
          aria-label={t(
            'shopPage.sellYourArt.strips.featuredShops.sellerCard.a11y.linkOverlay.label'
          )}
          href={
            link || Url.userLink(user.username, USER_PROFILE_SHOP_SUB_PATHS.ALL)
          }
          className={s['link-underlay']}
          biData={biData}
        />
        <div className={s['content']}>
          <IdentityVertical
            size="large"
            user={user}
            avatarClassName={s['avatar']}
            usernameClassName={s['username']}
            withDetails={!multiArt}
            withLinks={!multiArt}
          />
          {withShopCta && (
            <SolidButton
              className={s['cta']}
              size="small"
              variant="main"
              href={link || Url.userLink(user.username)}
              biData={biData}
            >
              {t(
                'shopPage.sellYourArt.strips.featuredShops.sellerCard.button.label'
              )}
            </SolidButton>
          )}
        </div>
      </BiLoggerContextProvider>
    </Section>
  );
};

SellerCard.displayName = 'SellerCard';
export default SellerCard;

const getBackgroundUrl = (deviation): string | undefined => {
  const info = getInfoToDisplayImageDeviation({
    deviation,
    width: parseInt(s['background-picture-width']),
  });
  if (info) {
    return `url(${info.pickedImage.src})`;
  }
};

const BackgroundDeviation: React.FC<{
  deviation?: PapiDeviation;
  size: 'large' | 'medium';
}> = ({ deviation, size }) => {
  return (
    <div
      role="presentation"
      className={c(
        s['single-background'],
        !deviation && s['no-image'],
        deviation && deviation.isMature && s['mature-blur'],
        s[`single-background-${size}`]
      )}
      style={{
        backgroundImage: deviation ? getBackgroundUrl(deviation) : undefined,
      }}
    />
  );
};
