import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PapiTag } from '@wix/da-papi-types';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import Heading from '@wix/da-a11y/pkg/components/headings/Heading';
import EmptyResult from '../../../../EmptyResult';
import BasePageLayout from '../../../_partials/BasePageLayout';
import DefaultPageContent from '../../../_partials/DefaultPageContent';
import { DailyChallengeArray } from '../DailyChallenges/types';
import OrderDropdown from '../../../_partials/OrderDropdown';
import TagSliderSubnav from '../../../_partials/TagSliderSubnav';
import DailyChallengeSlider from './DailyChallengePageHeader/DailyChallengeSlider';
import SelectedDailyChallengeHeading from '../DailyChallenges/SelectedDailyChallengeHeading';

import s from './DailyChallengeTagPage.scss';

// todo: if/when merging daily_challenge_v2, probably want to do a className audit

export interface Props {
  tag?: PapiTag;
  dailyChallenges?: DailyChallengeArray;
}

const DailyChallengeTagPage: React.FC<Props> = ({ tag, dailyChallenges }) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);
  const isDesktop = !isMobile;

  if (!tag) {
    return <EmptyResult />;
  }

  const sliders = (
    <>
      <TagSliderSubnav
        className={s['tag-slider-subnav']}
        withClearButton
        withTodaysTagIcon
      />
      <DailyChallengeSlider
        className={s['slider']}
        contentClassName={s['slider-content']}
        tag={tag.tag}
        dailyChallenges={dailyChallenges}
      />
    </>
  );

  return (
    <BasePageLayout
      aboveHeader={isMobile && sliders}
      headerLeftSideContent={
        <Heading className={s['title']}>
          {t('tagPage.dailyChallenge.title')}
        </Heading>
      }
      headerRightSideContent={<OrderDropdown className={s['order-dropdown']} />}
      headerIsStickyOnScrollUp={false}
      withNetworkBar={false}
      withNavigation={false}
      withTabHeader={isDesktop}
      contentClassName={s['content-block']}
    >
      {isDesktop && sliders}

      <SelectedDailyChallengeHeading
        className={s['current-challenge-header']}
        tag={tag.tag}
        tagItemId={tag.itemId}
        dailyChallenges={dailyChallenges}
      />

      <DefaultPageContent />
    </BasePageLayout>
  );
};
DailyChallengeTagPage.displayName = 'DailyChallengeTagPage';

export default DailyChallengeTagPage;
