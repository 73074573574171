import React, { useContext } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import type { PapiUser } from '@wix/da-papi-types';
import DialogBanner from '@wix/da-ds/pkg/DialogBanner';
import BirthdayCake from '@wix/da-ds/pkg/Icons/16x16/BirthdayCake';
import GhostButton from '@wix/da-ds/pkg/Buttons/GhostButton';
import { MobileContext } from '@wix/da-react-context/pkg/MobileContext';
import {
  ThemeOptions,
  ThemeOverride,
} from '@wix/da-react-context/pkg/ThemeContext';
import UserLink from '@wix/da-shared-react/pkg/User/Link/UserLink';
import GiftCoreBirthdayBanner from './GiftCoreBirthdayBanner';

import s from './BirthdayBanner.scss';
// asdsds

interface Props {
  user: PapiUser;
  className?: string;
}

const BirthdayBanner: React.FC<Props> = ({ className, user }) => {
  const { t } = useTranslation();
  const isMobile = useContext(MobileContext);

  const shouldUpsellCore = !user.isGroup;
  if (shouldUpsellCore) {
    return <GiftCoreBirthdayBanner className={className} user={user} />;
  }

  const { username } = user;
  return (
    <DialogBanner
      className={classnames(s['root'], className)}
      message={
        <ThemeOverride theme={ThemeOptions.LIGHT}>
          <span className={s['message']}>
            <BirthdayCake
              disableDefaultColors
              className={s['cake-icon']}
              role="presentation"
            />

            <span className={s['sale']}>
              {t(
                isMobile
                  ? 'watch.birthday.banner.text.mobile'
                  : 'watch.birthday.banner.text',
                { username }
              )}
            </span>
          </span>
        </ThemeOverride>
      }
      customCtaButton={
        <UserLink user={user} biData={{ sectionname: 'birthday_banner' }}>
          <GhostButton
            size="small"
            variant="main"
            className={s['button']}
            isFakeButton
          >
            {t(
              isMobile
                ? 'watch.birthday.banner.button.mobile'
                : 'watch.birthday.banner.button'
            )}
          </GhostButton>
        </UserLink>
      }
    />
  );
};
BirthdayBanner.displayName = 'BirthdayBanner';

export default BirthdayBanner;
