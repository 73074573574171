import { PageViewBiEvent } from '@wix/da-bi/pkg/events';
import { AppState } from '../types/store';
import {
  getCurrentOrder,
  getThisGroup,
  getThisTag,
  getThisUser,
  getLayout,
} from './page';
import { getIsCurrentStreamEmpty } from '../selectors/stream';
import { ResourceType } from '@wix/da-shared-react/pkg/types/resource';
import { BrowseLayout } from '../../constants';

function getBiView(state: AppState): string {
  if (state.currentPageData?.pageType === 'search') {
    return 'search';
  } else if (state.currentPageData?.pageType === 'shop') {
    return 'shop';
  }
  // all the non-search pages should return 'browse' for view
  return 'browse';
}

function getComponent(state: AppState): string | undefined {
  if (state.currentPageData?.pageType === 'search') {
    const searchType = state.currentPageData?.searchType ?? 'unknown';
    return searchType === 'all' ? 'search_home' : searchType;
  } else if (state.currentPageData?.pageType === 'shop') {
    const searchType = state.currentPageData?.searchType ?? 'unknown';
    return searchType === 'all' ? 'shop_home' : searchType;
  }

  return `${state.currentPageData?.pageType ?? 'home'} ${
    state.currentPageData?.feedType ?? ''
  }`.trim();
}

export function getBiDetails(state: AppState) {
  switch (state.currentPageData?.pageType) {
    case 'groups':
      return {
        typeid: ResourceType.GROUP,
        itemid: getThisGroup(state)?.userId,
        is_empty_state_view: getIsCurrentStreamEmpty(state)
          ? (1 as const)
          : (0 as const),
      };
    case 'popular':
      return {
        sort_type: getCurrentOrder(state),
      };
    case 'tag':
      return {
        typeid: getThisTag(state)?.typeId,
        itemid: getThisTag(state)?.itemId,
      };
    case 'watch':
      return {
        typeid: getThisUser(state) ? ResourceType.USER : undefined,
        itemid: getThisUser(state)?.userId,
        view_mode:
          getLayout(state) === BrowseLayout.Stacked
            ? ('stacked' as const)
            : ('grid' as const),
        is_empty_state_view: getIsCurrentStreamEmpty(state)
          ? (1 as const)
          : (0 as const),
      };
    case 'home':
      return {
        is_empty_state_view: getIsCurrentStreamEmpty(state)
          ? (1 as const)
          : (0 as const),
      };
    default:
      return {};
  }
}

export function getPageViewData(
  state: AppState
): Omit<PageViewBiEvent, 'evid'> {
  return {
    view: getBiView(state),
    component: getComponent(state),
    metadata: state.currentPageData?.biMetaData,
    ...getBiDetails(state),
  };
}
